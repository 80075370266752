import Box from '@gtg/components/box'
import Container from '@gtg/components/container'
import Footer from '@gtg/components/footer'
import Nav from '@gtg/components/nav'
import Seo from '@gtg/components/seo'
import { fontSize } from '@gtg/styles/theme'
import media from '@gtg/utils/media-queries'
import React, { useState } from 'react'
import styled from 'styled-components'
import GlobalWrapper from '../components/globalWrapper'

const windowGlobal = typeof window !== 'undefined' && window

const Form = styled.form`
  label {
    font-size: ${fontSize.f5};
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
  margin-top: 4rem;
  ${media.sm`
    margin-top: 2rem;
  `}
`

const Email = styled.input`
  max-width: 50%;
  ${media.sm`
    max-width: 100%;
  `}
`

const Message = styled.textarea`
  height: 250px;
`
const Section = styled.section`
  min-height: calc(100vh - 321px);
  ${media.sm`
    min-height: calc(100vh - 439px);
  `}
`

const SubmitButton = styled.button`
  margin-top: 2rem;
  padding: 1.25rem 6rem;
  background: #006e9a;
  font-weight: 600;
  letter-spacing: 0.3px;
  ${media.xl`
    padding: 1rem 3rem;
  `}
  ${media.sm`
    width: 100%;
    margin-top: 1rem;
    padding: 1.25rem 1rem;
  `}
  :hover {
    background: #009cda;
  }
`

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Contact = () => {
  const [status, setStatus] = useState()
  const submitForm = ev => {
    ev.preventDefault()
    const form = ev.target
    const data = new windowGlobal.FormData(form)
    const xhr = new windowGlobal.XMLHttpRequest()
    xhr.open(form.method, form.action)
    xhr.setRequestHeader('Accept', 'application/json')
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== windowGlobal.XMLHttpRequest.DONE) return
      if (xhr.status === 200) {
        form.reset()
        setStatus('SUCCESS')
      } else {
        setStatus('ERROR')
      }
    }
    xhr.send(data)
  }
  return (
    <GlobalWrapper>
      <Seo title="Kontakt" />
      <FlexContainer>
        <Nav caps wMax="xl" withBorder />
        <Section>
          <Container wMax="md">
            <Box pxMax="md" pyMax="lg">
              <h1>Anfrage</h1>
              <Form
                onSubmit={submitForm}
                action="https://formspree.io/xdopvkrw"
                method="POST"
              >
                <label htmlFor="mail">E-Mail</label>
                <Email
                  type="email"
                  id="mail"
                  placeholder="E-Mail-Adresse"
                  name="_replyto"
                  required
                />
                <label htmlFor="message">Nachricht</label>
                <Message
                  name="message"
                  id="message"
                  placeholder="Nachricht verfassen..."
                  required
                />
                <br />
                {status === 'SUCCESS' ? (
                  <p>Vielen Dank für Ihre Anfrage!</p>
                ) : (
                  <SubmitButton type="submit">Senden</SubmitButton>
                )}
                {status === 'ERROR' && (
                  <p>
                    Fehler. Bitte senden Sie eine E-Mail an{' '}
                    <a mailto="info@stretchzeltbayern.de">
                      info@stretchzeltbayern.de
                    </a>
                  </p>
                )}
              </Form>
            </Box>
          </Container>
        </Section>
        <Footer background="#0c1821" />
      </FlexContainer>
    </GlobalWrapper>
  )
}

export default Contact
